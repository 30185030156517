//import * as ActiveStorage from '@rails/activestorage'
//ActiveStorage.start()

import * as Turbo from '@hotwired/turbo'
Turbo.start()

import 'bootstrap'

//import 'controllers'
import('~/src/alpine.js')
import('~/src/turbo-scroll.js')
